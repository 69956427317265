import Seo from '@atoms/Seo/Seo';
import { useIsGreaterThanMd } from '@hooks/useMediaQuery';
import CtaButton from '@molecules/CtaButton/CtaButton';
import BrandLogo from '@organisms/BrandPage/BrandLogo/BrandLogo';
import ClientsSection from '@organisms/BrandPage/ClientsSection/ClientsSection';
import CtaSection from '@organisms/BrandPage/CtaSection/CtaSection';
import HeroSection from '@organisms/BrandPage/HeroSection/HeroSection';
import MobileTestimonials from '@organisms/BrandPage/MobileTestimonials/MobileTestimonials';
import OpeningAnimation from '@organisms/BrandPage/OpeningAnimation/OpeningAnimation';
import ServicesSection from '@organisms/BrandPage/ServicesSection/ServicesSection';
import StoriesSection from '@organisms/BrandPage/StoriesSection/StoriesSection';
import TestimonialsSection from '@organisms/BrandPage/TestimonialsSection/TestimonialsSection';
import ReactLenis from '@studio-freight/react-lenis';
import { BrandPage as Props } from '@type-declarations/page';
import clsx from 'clsx';
import { useMotionValueEvent, useScroll } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import styles from './BrandPageTemplate.module.scss';

const VIEW_POINT = 60;

function BrandPageTemplate({
  page: {
    title,
    seo,
    contactTitle,
    contactCtaPrimary,
    contactCtaSecondary,
    servicesLabel,
    services,
    testimonials,
    story,
    closingSentence,
    audioLabel,
    audioSubLabel,
    audioImage,
    audioFile,
    video,
    menuItems,
    clientsLabel,
    clients,
  },
}: {
  page: Props;
}) {
  const isGreaterThanMd = useIsGreaterThanMd();
  const [videoInView, setVideoInView] = useState(true);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [currentBackground, setCurrentBackground] = useState<
    'first' | 'second' | 'third'
  >('first');
  const [scrollState, setScrollState] = useState<'active' | 'after' | 'before'>(
    'before',
  );

  const storyRef = useRef(null);
  const { scrollYProgress: storyScrollProgress } = useScroll({
    target: storyRef,
    offset: [`start ${VIEW_POINT}%`, `end ${VIEW_POINT}%`],
  });

  useMotionValueEvent(storyScrollProgress, 'change', progress => {
    if (scrollState !== 'active' && progress > 0 && progress < 1) {
      setScrollState('active');
    } else if (scrollState !== 'before' && progress === 0) {
      setScrollState('before');
    } else if (scrollState !== 'after' && progress === 1) {
      setScrollState('after');
    }
  });

  /* Watch video viewport */
  const heroRef = useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll({
    target: heroRef,
    offset: [`start start`, `end start`],
  });

  useMotionValueEvent(scrollYProgress, 'change', progress => {
    if (progress >= 0 && progress < 1) {
      setVideoInView(true);
    } else {
      setVideoInView(false);
    }
    setHasScrolled(progress > 0.02);
  });

  /* Watch background references */
  const [
    [firstBackgroundRef, firstBackgroundInView],
    [secondBackgroundRef, secondBackgroundInView],
    [thirdBackgroundRef, thirdBackgroundInView],
  ] = [
    useInView(),
    useInView({
      rootMargin: '0px 0px -100% 0px',
    }),
    useInView({
      rootMargin: '0px 0px -100% 0px',
    }),
  ];

  /* Manage body background color */
  useEffect(() => {
    if (thirdBackgroundInView) {
      setCurrentBackground('third');
    } else if (secondBackgroundInView) {
      setCurrentBackground('second');
    } else {
      setCurrentBackground('first');
    }
  }, [firstBackgroundInView, secondBackgroundInView, thirdBackgroundInView]);

  useEffect(() => {
    // Disable automatic scroll restoration
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }

    // Reset scroll position to top on component mount
    window.scroll({ top: 0, behavior: 'instant' });
  }, []);

  return (
    <>
      <Seo title={title} seo={seo} />
      <ReactLenis
        options={{
          lerp: 0.08, // Default is 0.1
        }}
        root
      >
        <OpeningAnimation />

        <main
          className={clsx(
            styles.main,
            styles[currentBackground],
            !videoInView && styles.showHeader,
          )}
        >
          <header className={styles.header}>
            <BrandLogo hasScrolled toBottom={scrollState === 'after'} />

            {menuItems.map(menuItem => (
              <CtaButton
                className={styles.cta}
                variant="minimal"
                cta={menuItem}
                key={menuItem.id}
              />
            ))}
          </header>

          {/* Light green */}
          <div
            className={clsx(
              styles.backgroundRef,
              currentBackground === 'second' && styles.hidden,
            )}
            ref={firstBackgroundRef}
          >
            <HeroSection
              title={title}
              video={video}
              inView={videoInView}
              heroRef={heroRef}
              scrollYProgress={scrollYProgress}
              hasScrolled={hasScrolled}
            />

            <StoriesSection
              story={story}
              closingSentence={closingSentence}
              audioLabel={audioLabel}
              audioSubLabel={audioSubLabel}
              audioImage={audioImage}
              audioFile={audioFile}
              ref={storyRef}
              scrollState={scrollState}
            />
          </div>

          {/* White */}
          <div className={styles.backgroundRef} ref={secondBackgroundRef}>
            {isGreaterThanMd ? (
              <TestimonialsSection testimonials={testimonials} />
            ) : (
              <MobileTestimonials testimonials={testimonials} />
            )}
          </div>

          {/* Dark green */}
          <div className={styles.backgroundRef} ref={thirdBackgroundRef}>
            <ClientsSection label={clientsLabel} clients={clients} />

            <ServicesSection label={servicesLabel} services={services} />

            <CtaSection
              title={contactTitle}
              primaryCta={contactCtaPrimary}
              secondaryCta={contactCtaSecondary}
            />
          </div>
        </main>
      </ReactLenis>
    </>
  );
}

export default BrandPageTemplate;
